<template>
  <div class="bac">
    <div class="main">
      <div class="top">
        <div class="top_left">
          <div class="tx"></div>
          <div class="top_info">
            <p>{{ Info.user.username }}</p>
            <button @click="this.isChangeInfoDialogVisible = true">编辑信息</button>
            <button>修改密码</button>
          </div>
        </div>
        <div class="top_right">
          <div class="phone" @click="showBindDialog('手机')" style="cursor: pointer">
            <i style="font-size:.28rem" class="fa">&#xf10b</i>
            {{ isBindPhone }}
          </div>
          <div class="mail" @click="showBindDialog('邮箱')" style="cursor: pointer">
            <i style="font-size:.24rem" class="fa">&#xf0e0</i>
            {{ isBindEmail }}
          </div>
          <div class="wechat" @click="bindWeChet()">
            <i style="font-size:.24rem;color: #ec414d" class="fa" >&#xf1d7</i>
            {{ isBindWe }}
          </div>
        </div>
      </div>
      <div class="medium" >
        <div class="medium_head">
          <i></i>
          <p>基本信息</p>
        </div>
        <div class="medium_content">
          <div class="content_left">
            <div class="nicheng">
              <i>昵称：</i><p>{{ Info.user.username }}</p>
            </div>
            <div class="city">
              <i>城市：</i><p>{{ Info.user.address }}</p>
            </div>
            <div class="address">
              <i>详细地址：</i><p>{{ Info.userMessage.fullAddress }}</p>
            </div>
            <div class="introduce">
              <i>个人简介：</i><p>{{ Info.userMessage.info }}</p>
            </div>
            <div class="company_name">
              <i>公司名称：</i><p>{{ Info.userMessage.companyName }}</p>
            </div>

          </div>
          <div class="content_right">
            <div class="name">
              <i>姓名：</i>
              <p>{{ Info.userMessage.fullName }}</p>
            </div>
            <div class="sex">
              <i>性别：</i><p>{{ Info.userMessage.sex }}</p>
            </div>
            <div class="birthday">
              <i>生日：</i><p>{{ Info.userMessage.birth }}</p>
            </div>
            <div class="profession">
              <i>职业：</i><p>{{ Info.userMessage.profession }}</p>
            </div>
            <div class="start">
              <i>开始工作时间：</i><p>{{ Info.userMessage.workingBeginTime }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="medium_head">
          <i></i>
          <p>专业信息</p>
        </div>
        <div class="bottom_content">
          <div class="content_left">
            <div class="nicheng">
              <i>公司：</i><p>{{ Info.userMessage.companyName }}</p>
            </div>
            <div class="city">
              <i>职位</i><p>{{ Info.userMessage.position }}</p>
            </div>
            <div class="address">
              <i>业务领域：</i><p>{{ Info.userMessage.areaGood }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="isChangeInfoDialogVisible" title="编辑个人信息" width="60%" center>
    <el-space>
      <el-space direction="vertical">
        <el-input v-model="this.changeInfo.userMessage.fullName" placeholder="姓名"/>
        <el-input v-model="this.changeInfo.user.username" placeholder="昵称"/>
        <el-input v-model="this.changeInfo.user.address" placeholder="城市"/>
        <el-input v-model="this.changeInfo.userMessage.fullAddress" placeholder="详细地址"/>
        <el-input v-model="this.changeInfo.userMessage.companyName" placeholder="公司名称"/>
        <el-input v-model="this.changeInfo.userMessage.info" placeholder="个人简介"/>
        <el-input v-model="this.changeInfo.userMessage.sex" placeholder="性别"/>
      </el-space>
      <el-space direction="vertical">
        <el-date-picker v-model="changeInfo.userMessage.birth" type="date" format="YYYY/MM/DD" value-format="x" placeholder="生日"/>
        <el-input v-model="this.changeInfo.userMessage.profession" placeholder="职业"/>
        <el-date-picker v-model="changeInfo.userMessage.workingBeginTime" type="date" format="YYYY/MM/DD" value-format="x" placeholder="开始工作时间"/>
        <el-input v-model="this.changeInfo.userMessage.position" placeholder="职位"/>
        <el-cascader :options="options" :show-all-levels="false" v-model="this.areaTemp" placeholder="业务领域"/>
      </el-space>
    </el-space>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isChangeInfoDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="isBindDialogVisible" title="绑定信息" width="30%" center>
    <el-space direction="vertical" alignment="flex-start">
      <el-input v-model="this.bindSubForm.phone" :placeholder="bindDialogText"/>
      <el-space>
        <el-input v-model="this.bindSubForm.code" placeholder="验证码"/>
        <el-button @click="sendCode(bindDialogText)">发送验证码</el-button>
      </el-space>
    </el-space>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isBindDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitBindInfo(bindDialogText)">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "PersonalInformation",
  data() {
    return {
      options:[
        {
          value: '产品设计',
          label: '产品设计',
          children: [
            {
              value: '医疗设备',
              label: '医疗设备',
            },
            {
              value: '仪器设备',
              label: '仪器设备',
            },
            {
              value: '消费电子',
              label: '消费电子',
            },
            {
              value: '交通出行',
              label: '交通出行',
            },
            {
              value: '工业设备',
              label: '工业设备',
            },
            {
              value: '机器人',
              label: '机器人',
            },]
        },
        {
          value: '研发工程',
          label: '研发工程',
          children: [
            {
              value: '结构设计',
              label: '结构设计',
            },
            {
              value: '自动化设计',
              label: '自动化设计',
            },]
        },
        {
          value: '传播设计',
          label: '传播设计',
          children: [
            {
              value: '品牌vi',
              label: '品牌vi',
            },
            {
              value: '电商视觉',
              label: '电商视觉',
            },
            {
              value: '网站/APP',
              label: '网站/APP',
            },
            {
              value: '产品包装',
              label: '产品包装',
            },
            {
              value: 'Logo设计',
              label: 'Logo设计',
            },
            {
              value: '画册设计',
              label: '画册设计',
            },
            {
              value: 'UI设计',
              label: 'UI设计',
            },]
        },
        {
          value: '空间设计',
          label: '空间设计',
          children: [
            {
              value: '商业空间',
              label: '商业空间',
            },
            {
              value: '厂房改造',
              label: '厂房改造',
            },
            {
              value: '会展陈列',
              label: '会展陈列',
            },
            {
              value: '空间导视',
              label: '空间导视',
            },]
        }
      ],
      isChangeInfoDialogVisible:false,
      isBindDialogVisible:false,
      Info: {
        userMessage: {
          id: 29,
          fullName: "姓名",
          fullAddress: "详细地址",
          sex: "性别",
          birth: 1111111111111,
          info: "个人简介",
          profession: "职业",
          companyName: "公司名称",
          workingBeginTime: 1111111111111,
          company: "公司",
          position: "职位",
          researchDirection: "研究方向",
          uid: 35
        },
        isBindPhone: 1,
        isBindEmail: 0,
        isBindWx: 0,
        user: {
          id: 35,
          username: "designer35",
          password: "18f3fa0440947cde825400ebb66c919b",
          phone: "19811733128",
          email: null,
          role: "designer",
          address: "城市",
          areaGood: "业务领域",
          selectedNum: null,
          headPic: null,
        }
      },
      changeInfo:{
        userMessage: {
          id: 29,
          fullName: "姓名",
          fullAddress: "详细地址",
          sex: "性别",
          birth: 1111111111111,
          info: "个人简介",
          profession: "职业",
          companyName: "公司名称",
          workingBeginTime: 1111111111111,
          company: "公司",
          position: "职位",
          researchDirection: "研究方向",
          uid: 35
        },
        isBindPhone: 1,
        isBindEmail: 0,
        isBindWx: 0,
        user: {
          id: 35,
          username: "designer35",
          password: "18f3fa0440947cde825400ebb66c919b",
          phone: "19811733128",
          email: null,
          role: "designer",
          address: "城市",
          areaGood: "业务领域",
          selectedNum: null,
          headPic: null,
        }
      },
      isBindPhone:'',
      isBindEmail:'',
      isBindWe:'',
      bindDialogText:'',
      bindSubForm:{
        phone:'',
        email:'',
        code:'',
      },
      areaTemp:'',




      QRCode:'',
      QRCodeKey:'',
      QRState:'waiting',
      QRCheckForm:{
        key:'',
      },
      codeState:0,
      QRCallBackForm:{},
      QRCheckUserStateForm:{
        openid:''
      },
      QRBindForm:{
        type:'',
        role:'',
        userInfo:{},
      },
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo(){
      this.$http({
        method: 'post',
        url: '/getUserMessage',
        data: ''
      }).then(res => {
       this.Info = res.data;

       if (this.Info.isBindEmail === 1){
         this.isBindEmail = this.Info.user.email;
       }else {
         this.isBindEmail = '点击绑定邮箱'
       }

      if(this.Info.isBindPhone === 1){
        this.isBindPhone = this.Info.user.phone
      }else {
        this.isBindPhone = '点击绑定电话'
      }

      if (this.Info.isBindWx === 1){
        this.isBindWe = '微信已绑定'
      }else {
        this.isBindWe = '点击绑定微信'
      }

      this.changeInfo = res.data;
      })
    },
    submit(){
      this.$http({
        method: 'post',
        url: '/updateUserMessage',
        data: this.changeInfo
      }).then(res => {
        this.isChangeInfoDialogVisible = false;
        this.Info.userMessage.areaGood = this.areaTemp[1];
      })

    },
    showBindDialog(index){
      this.bindDialogText = index;
      this.isBindDialogVisible = true;
    },
    sendCode(index){
      if (index === '手机'){
        this.$http({
          method: 'post',
          url: '/sendPhoneCodeInBinding',
          data: this.changeInfo
        }).then(res => {
        })
      }else if (index === '邮箱'){
        this.$http({
          method: 'post',
          url: '/sendEmailCodeInBinding',
          data: this.changeInfo
        }).then(res => {
        })
      }
    },
    submitBindInfo(index){
      if (index === '手机'){
        this.$http({
          method: 'post',
          url: '/sendSmsInUpdatePassword',
          data: this.changeInfo
        }).then(res => {
        })
      }else if (index === '邮箱'){
        this.$http({
          method: 'post',
          url: '/bindEmail',
          data: this.changeInfo
        }).then(res => {
        })
      }
    },


    bindWeChet(){
      this.getQRCode();
    },
    getQRCode(){
      this.$http({
        method: 'post',
        url: '/wx/getWechatQtCode',
      }).then(res => {
        this.QRCode = res.data.url;
        this.QRCodeKey = res.data.key;
        this.QRCheckForm.key = this.QRCodeKey;
        //打开二维码窗口
        window.open(this.QRCode, "_blank", "top=300,left=300px,resizable,scrollbars,status,width=300,height=400,menubar=no,toolbar=no, status=no,scrollbars=no");

        this.timer = window.setInterval(() => {
          setTimeout(() => {
            this.checkQRState();
            if (this.QRCallBackForm.state === 2){
              window.clearInterval(this.timer)
              this.QRCheckUserStateForm.openid = this.QRCallBackForm.userInfo.openid;
              this.QRCheckUserState();
            }
          },0)
        },1000)
      })
    },

    checkQRState(){
      this.$http({
        method: 'post',
        url: '/wx/wxLogin/checkState',
        data:this.QRCheckForm
      }).then(res => {
        this.QRCallBackForm =res.data;
        this.QRBindForm = res.data;
      })
    },

    //判断是否为新用户
    QRCheckUserState(){
      this.$http({
        method: 'post',
        url: '/wx/isExistForScanCodeUser',
        data:this.QRCheckUserStateForm
      }).then(res => {
        if (res.data.res === 0){
          this.Bind()
        }else if (res.data.res === 1){
          ElMessage({
            message: '绑定失败，' + res.data.message,
            type: 'error',
          })
        }
      })
    },
    Bind(){
      this.$http({
        method: 'post',
        url: '/wx/bindWx',
        data:this.QRBindForm
      }).then(res => {
        if (res.data.code === 1){
          ElMessage({
            message: '绑定成功',
            type: 'success',
          })
          location.reload();
        }else {
          ElMessage({
            message: '绑定失败，' + res.data.message,
            type: 'error',
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.bac{
  width: 19.2rem;
  height: 100%;
  padding-left: 2.98rem;
  padding-top: .6rem;
  background-color: #f5f5f5;
}

.main{
  width: 14.58rem;
  height: 9.15rem;
  margin-left: .66rem;
  background-color: #ffffff;
  border: .01rem solid transparent;
}

.main .top{
  margin-left: .44rem;
  padding-top: .28rem;
}

.main .top .top_left{
  float: left;
  width: 7.56rem;
  height: 1.88rem;
  margin-right: .52rem;
}

.main .tx{
  float: left;
  width: 1.74rem;
  height: 1.74rem;
  border-radius: 50%;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.main .top_info{
  float: left;
  width: 5.64rem;
  height: 1.64rem;
  font-size: .2rem;
  margin-left: .18rem;
  margin-top: .22rem;

}

.main .top_left p:nth-of-type(1){
  width: .86rem;
  height: .2rem;
  /*float: left;*/
  font-size: .2rem;
  color: #212121;
  margin-bottom: .4rem;
}

.main .top_left p:nth-of-type(2){
  float: left;
  height: .2rem;
  font-size: .2rem;
  color: #5c5c5e;
  margin-bottom: .35rem;
}

.main .top .top_left a{
  display: block;
  float: left;
  font-size: .2rem;
  color: #0379db;
  margin-bottom: .35rem;
  /*margin-bottom: .35rem;*/

}

.main .top .top_left button{
  width: 1.21rem;
  height: .48rem;
  color:#ffffff;
  font-size: .22rem;
  border-radius: .05rem;
}

.main .top .top_left button:nth-of-type(1){
  background-color: #363636;
  margin-right: .35rem;
}

.main .top .top_left button:nth-of-type(2){
  background-color: #ec414d;
}

.top .top_right{
  padding-top: .13rem;
}

.top .top_right div{
  height: .38rem;
  line-height: .38rem;
  color: #212121;
  font-size: .16rem;
  margin-bottom: .28rem;
  padding-top: .05rem;
}

.top .top_right i{
  margin-right: .18rem;
  margin-bottom: -0.05rem;
}

.medium_head{
  width: 14.58rem;
  height: .5rem;
  margin-left: .47rem;
  margin-bottom: .28rem;

}

.medium_head i{
  display: block;
  float: left;
  width: .08rem;
  height: .48rem;
  margin-right: .27rem;
  background-color: #ec414d;
}

.medium_head p{
  float: left;
  width: 1.02rem;
  margin-top: .1rem;
  font-size: .24rem;
  color: #5c5c5c;
}

.medium .medium_content{
  position: relative;
  left: .5rem;
  width: 13.32rem;
  height: 3rem;
  border: .01rem solid #212121;
  border-radius: .05rem;
  padding-top: .36rem;
  margin-bottom: .24rem;
  color: #212121;
}

.bottom .bottom_content{
  position: relative;
  left: .5rem;
  width: 13.32rem;
  height: 1.85rem;
  border: .01rem solid #212121;
  border-radius: .05rem;
  padding-top: .26rem;
  margin-bottom: .24rem;
  color: #212121;

}

.content_left{
  position: absolute;
  left: 1.08rem;
  width: 6.6rem;
}

.content_left div{
  width: 100%;
  height: .24rem;
  line-height: .24rem;
  margin-bottom: .32rem;
  text-align: left;
}

.content_left p{
  float: left;
  height: .2rem;
  line-height: .2rem;
  font-size: .2rem;
}

.content_left i{
  display: block;
  float: left;
  height: .2rem;
  line-height: .2rem;
  font-size: .2rem;
}

.content_right{
  position: absolute;
  width: 5.62rem;
  height: 2.35rem;
  left: 7.7rem;
}


.content_right div{
  width: 100%;
  height: .24rem;
  line-height: .24rem;
  margin-bottom: .32rem;
  text-align: left;
}

.content_right p{
  float: left;
  height: .2rem;
  line-height: .2rem;
  font-size: .2rem;
}

.content_right i{
  display: block;
  float: left;
  height: .2rem;
  line-height: .2rem;
  font-size: .2rem;
}


</style>